@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: CenturyGothic;
  src: url('./fonts/GOTHIC.woff');
}

@font-face {
  font-family: CenturyGothic-Bold;
  src: url('./fonts/GOTHICB.woff');
}

@font-face {
  font-family: CenturyGothic-Bold-Italic;
  src: url('./fonts/GOTHICBI.woff');
}

@font-face {
  font-family: CenturyGothic-Italic;
  src: url('./fonts/GOTHICI.woff');
}

@font-face {
  font-family: ClanOT-Black;
  src: url('./fonts/ClanOT-Black.woff');
}

/* -------------------------------------------------- */

@font-face {
  font-family: Contrax;
  src: url('./fonts/conthrax-sb.woff');
}

@font-face {
  font-family: Sharp;
  src: url('./fonts/Sharp-Grotesk-Light-25-Regular.woff');
}

@font-face {
  font-family: FClanBold;
  src: url('./fonts/FFClanProBold.woff');
}

@font-face {
  font-family: FClanItal;
  src: url('./fonts/FFClanProItalic.woff');
}

@font-face {
  font-family: FClanReg;
  src: url('./fonts/FFClanProRegular.woff');
}

:root {
  --color-main: #16365E;
  --color-secundary: #54D2A2;
  --color-error: #FF4D6B;
  --color-success: #A4C54E;
  --color-darkBlue: #002342;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  color: #FFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}


body #root {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background: #FFF0;
}
*::-webkit-scrollbar-thumb {
  background: var(--color-main);
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #037549;
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title {
  padding: 0px !important;
}

.swal2-title br {
  font-size: 0 !important;
}

.swal2-image {
  margin: 3vw auto 1vw auto !important;
}

.closePopUp {
  position: absolute;
  top: -30px;
  right: 0%;
  color: #16365E !important;
  background-color: var(--color-secundary) !important;
}


/* ---------------------------- */

.preloader {
  width: 40px;
  height: 40px;

  border: 5px solid transparent;
  border-top: 5px solid var(--color-main);
  border-left: 5px solid var(--color-main);
  border-bottom: 5px solid var(--color-main);
  border-radius: 50%;

  margin: 10px auto;

  animation-name: girar;
  animation-duration: .9s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes girar {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ----------------------------------------- */

/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
